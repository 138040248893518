@import '~antd/dist/antd.less';
@import '~@ant-design/aliyun-theme/index.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.title {
  text-align: left;
}

.container {
  margin-top: 30px;
  margin-bottom: 20px;
}

.dg-header {
  font-size: 150%;
}

.ant-statistic-content-suffix {
  font-size: 14px !important;
}

.actions-button {
  background-color: transparent;
  border: none;
  outline: none;
  display: block;
  color: #0070cc;
}

.raw-logs-list {
  padding-left: 0;
  li {
    list-style: none;
    font-size: 16px;
    color: #000;
  }
}
